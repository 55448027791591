import BaseClinic from "../../components/layout/page/base-clinic"
import { graphql } from "gatsby"
import React from "react"
import useBadgesQuery from "../../hooks/use-badges-query"
import { GatsbyImage } from "gatsby-plugin-image"

const FranklinClinic = ({ data }) => {
  const {
    markdownRemark: {
      html,
      frontmatter: { title },
    },
  } = data
  const badges = useBadgesQuery()

  return (
    <BaseClinic title={title} html={html}>
      <div className="mt-10 md:mt-20">
        <GatsbyImage alt="Franklin Method certifications" image={badges} />
      </div>
    </BaseClinic>
  )
}

export default FranklinClinic

export const query = graphql`
  query FranklinQuery {
    markdownRemark(frontmatter: { name: { eq: "franklin" } }) {
      html
      frontmatter {
        title
      }
    }
  }
`

import React from "react"

import BaseChild from "./base-child"
import useClinicsHeroQuery from "../../../hooks/use-clinics-hero-query"

const BaseClinic = ({ title, html, children }) => {
  const gatsbyImageData = useClinicsHeroQuery()
  return (
    <BaseChild title={title} hero={gatsbyImageData} html={html}>
      {children}
    </BaseChild>
  )
}

export default BaseClinic

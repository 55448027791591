import React from "react"

import BaseLayout from "../base-layout"
import { ContentWrapper, Hero } from "@wonder-horse/shared"

const BaseChild = ({ title, hero, html, htmlClass, children }) => {
  return (
    <BaseLayout title={title}>
      <Hero img={hero} />
      <ContentWrapper>
        <div className="w-full md:w-3/5 mb-5 md:mb-28">
          <h1 className="text-center mb-10">{title}</h1>
          {html && (
            <div
              className={htmlClass ? htmlClass : ""}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          )}
          {children}
        </div>
      </ContentWrapper>
    </BaseLayout>
  )
}

export default BaseChild
